import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{staticClass:"problem-bio-panel"},[_c(VCol,{attrs:{"cols":"12"}},[_c(VCard,{staticClass:"pt-10"},[_c(VCardText,[_c('h2',{staticClass:"text-xl font-weight-semibold mb-2"},[_vm._v("Problem Details")]),_c(VDivider),_c(VList,[_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("problem Name:")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.problemData.name))])]),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Mobile Number:")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.problemData.mobile_number))])]),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("Emergency Number:")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.problemData.emergency_number))])]),_c(VListItem,{staticClass:"px-0 mb-n2",attrs:{"dense":""}},[_c('span',{staticClass:"font-weight-medium me-2"},[_vm._v("kitchen Name:")]),_c('span',{staticClass:"text--secondary"},[_vm._v(_vm._s(_vm.problemData.kitchen_name))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }