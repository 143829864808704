<template>
  <div id="problem-view">
    <v-row>
      <v-col cols="12">
        <problem-bio-panel
        v-if="problemData"
          :problem-data="problemData"
          :is-plan-upgrade-dialog-visible.sync="isPlanUpgradeDialogVisible"
          @refresh="udpateproblemData"
        ></problem-bio-panel>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import router from '@/router'
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'

// eslint-disable-next-line object-curly-newline
import { mdiAccountOutline } from '@mdi/js'
import problemStoreModule from '../problemStoreModule'
import problemBioPanel from './problem-bio-panel/ProblemBioPanel.vue'

export default {
  components: {
    problemBioPanel,
  },
  methods: {
    udpateproblemData(value) {
      this.problemData = value
    },
  },
  setup() {
    const problem_APP_STORE_MODULE_NAME = 'app-problem'

    // Register module
    if (!store.hasModule(problem_APP_STORE_MODULE_NAME)) store.registerModule(problem_APP_STORE_MODULE_NAME, problemStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(problem_APP_STORE_MODULE_NAME)) store.unregisterModule(problem_APP_STORE_MODULE_NAME)
    })

    const problemData = ref(null)
    const selectedPlan = ref('')
    const isPlanUpgradeDialogVisible = ref(false)

    store
      .dispatch('app-problem/fetchProblems')
      .then(response => {
        problemData.value = response.data.data.filteredData.filter(ele=>ele.id ==router.currentRoute.params.id)[0]
        selectedPlan.value = response.data.currentPlan
        console.log('problemData.value', problemData.value)
      })
      .catch(error => {
        if (error.response.status === 404) {
          problemData.value = {}
        }
      })


    return {
      selectedPlan,
      isPlanUpgradeDialogVisible,
      problemData,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
