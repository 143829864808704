<template>
  <v-row class="problem-bio-panel">
    <!-- problem profile -->
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-text>
          <h2 class="text-xl font-weight-semibold mb-2">Problem Details</h2>

          <v-divider></v-divider>

          <v-list>
            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">problem Name:</span>
              <span class="text--secondary">{{ problemData.name }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Mobile Number:</span>
              <span class="text--secondary">{{ problemData.mobile_number }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">Emergency Number:</span>
              <span class="text--secondary">{{ problemData.emergency_number }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 mb-n2">
              <span class="font-weight-medium me-2">kitchen Name:</span>
              <span class="text--secondary">{{ problemData.kitchen_name }}</span>
            </v-list-item>

          </v-list>
        </v-card-text>
      </v-card>

    </v-col>
  </v-row>
</template>

<script>
import { mdiBriefcaseVariantOutline, mdiCheck, mdiCheckboxBlankCircle, mdiStarOutline } from '@mdi/js'

export default {

  props: {
    problemData: {
      type: Object,
      required: true,
    },
    isPlanUpgradeDialogVisible: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    updateproblemData(newValue) {
      this.$emit('refresh', newValue)
    },
  },
  setup() {

    const standardPlan = {
      plan: 'Standard',
      price: 99,
      benefits: ['10 Users', 'Up to 10GB storage', 'Basic Support'],
    }



    return {
      standardPlan,
      icons: {
        mdiCheck,
        mdiBriefcaseVariantOutline,
        mdiCheckboxBlankCircle,
        mdiStarOutline,
      },
    }
  },
}
</script>
